<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <logo />
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your username and we'll send you instructions to reset your
            password
          </b-card-text>

          <validation-observer ref="forgotPasswordForm">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Username"
                label-for="forgot-password-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Send reset link
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'authentication-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import {
  BButton,
  BCardTitle,
  BCardText,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BRow,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import Logo from '@/layouts/components/Logo.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import useAuthentication from '@/modules/authentication/composables/useAuthentication';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

export default {
  setup() {
    const toast = useToast();
    const { skin } = useAppConfig();
    const { forgotPassword } = useAuthentication();

    const username = ref('');
    const forgotPasswordForm = ref('');

    return {
      // Reactive
      username,
      forgotPasswordForm,

      // Computed
      imgUrl: computed(() =>
        skin.value === 'dark'
          ? require('@/assets/images/pages/forgot-password-v2-dark.svg')
          : require('@/assets/images/pages/forgot-password-v2.svg')
      ),

      // Method
      validationForm: () => {
        forgotPasswordForm.value.validate().then(async (validation) => {
          if (validation) {
            const { success, message } = await forgotPassword(username.value);

            success
              ? toast.success({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                  },
                })
              : toast.error({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                  },
                });
          }
        });
      },

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BImg,
    BLink,
    BRow,
    ValidationObserver,
    ValidationProvider,
    Logo,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
